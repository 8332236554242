import { useEffect } from 'react'
import { useRouter } from 'next/router'

const DynamicCSS = () => {
  const router = useRouter()

  useEffect(() => {
    if (router.pathname !== '/') {
      console.log('not the home page')
      // Apply the CSS when the element is found
      const floatingButtonStyles = document.createElement('style')
      floatingButtonStyles.innerHTML = `
        #ot-sdk-btn-floating {
          left: 180px !important;
          bottom: 28px !important;
        }
      `

      document.head.appendChild(floatingButtonStyles)
    }
  }, [])

  return null
}

export default DynamicCSS
